import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

const ScreenshotSection = () => {
  const data = useStaticQuery(graphql`
    query {
      carousel1: file(relativePath: { eq: "home/carousel/mockup1.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel2: file(relativePath: { eq: "home/carousel/mockup2.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel3: file(relativePath: { eq: "home/carousel/mockup3.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel4: file(relativePath: { eq: "home/carousel/mockup4.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel5: file(relativePath: { eq: "home/carousel/mockup5.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel6: file(relativePath: { eq: "home/carousel/mockup6.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      carousel7: file(relativePath: { eq: "home/carousel/mockup7.png" }) {
        childImageSharp {
          fluid(maxHeight: 620, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [currentSlide, setCurrentSlide] = useState(1)

  const settings = {
    infinite: true,
    arrows: true,
    dots: true,
    easing: "easeInOut",
    mobileFirst: true,
    afterChange: current => setCurrentSlide(current + 1),
  }

  return (
    <section className="section section-screenshot">
      <div className="container">
        <div className="app-screenshot">
          <Slider classNameName="gallery" {...settings}>
            <div className="gallery-cell">
              <div className="mockup">
                <Img
                  fluid={data.carousel1.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Resolva tudo para o seu carro de maneira simples, rápida e
                segura
              </span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel2.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Ative seu estacionamento rotativo em poucos segundos
              </span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel3.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">Pague como quiser</span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel4.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Parcele multas, IPVA e licenciamento em até 12x
              </span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel5.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Viaje por todo o Brasil sem pegar filas no pedágio
              </span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel6.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Encontre o telefone do seu seguro sempre que precisar
              </span>
            </div>
            <div className="gallery-cell">
              <div className="mockup">
              <Img
                  fluid={data.carousel7.childImageSharp.fluid}
                  alt="Tela do App"
                />
              </div>
              <span className="description">
                Receba alertas quando for o seu dia de rodízio
              </span>
            </div>
          </Slider>
          <div className="slides-counter">
            <span className="current-slide">{currentSlide}</span> /
            <span className="total-slides">{" "}7</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScreenshotSection
